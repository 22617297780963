<template>
  <div>
    <div style="display: flex" class="full-width-padding">
      <p class="text-hoverable" @click="$router.go(-1)" style="display: flex; justify-content: flex-start; align-items: center">
        <BackIcon style="margin-right: 10px"/>Tillbaka
      </p>
    </div>
    <p class="auth text--warning" style="display: block; text-align: center">Ladda inte om sidan, då försvinner ändringarna!</p>

    <div class="admin-collection-form">
      <div style="display: flex; justify-content: end">
        <p class="auth text-hoverable" :style="{color: !english ? '#269400' : 'black', fontWeight: !english ? 'bold' : 'normal'}" style="margin-right: 10px" @click="english = false">Svenska</p>
        <p class="auth text-hoverable" :style="{color: english ? '#269400' : 'black', fontWeight: english ? 'bold' : 'inherit'}" @click="english = true">Engelska</p>
      </div>
      <h3 style="text-align: left">Samlingstitel</h3>
      <input class="auth" type="text" style="margin-bottom: 50px" v-model="title[+english]">
      <h3 style="text-align: left">Beskrivning</h3>
      <textarea class="auth" type="text" v-model="description[+english]" style="max-width: 100%; min-height: 100px; max-height: 500px">
      </textarea>
      <h3 style="text-align: left">Bild</h3>
      <div style="display: flex; flex-direction: column; align-items: center; ">
        <button class="auth" style="position: relative; margin-bottom: 20px" @click="$refs.input.click()">
          <div v-if="imageLoading" class="auth-loader" style="position: absolute; left: 15px; top: 12px"></div>
          Välj bild
        </button>
        <input type="file" ref="input" style="display: none;" @change="updateImages">
        <img v-if="image" :src="image">
        <p v-if="image" class="auth text-hoverable" @click="removeImage">Ta bort</p>
      </div>
      <h3 style="text-align: left">Filmer</h3>
      <div class="admin-video-list" style="text-align: left">
        <draggable v-model="videos"
                   group="vids"
                   @start="drag=true"
                   @end="drag=false"
                   handle=".handle">
          <div v-for="(video, index) in videos" :key="index" style="display: flex; align-items: center"> 
            <p class="handle">*</p>
            <div style="border: 1px solid black; margin: 10px; padding: 10px; flex: 1; border-radius: 10px">
              <div style="display: flex; justify-content: space-between">
                <p class="auth" style="margin: 0">Titel: </p>
                <p class="auth text-hoverable text--warning" @click="removeVideo(video)">Ta bort</p>
              </div>
              <input class="auth" type="text" style="margin-bottom: 20px" v-model="video.title[+english]">
            
              <p class="auth" style="margin: 0">Youtube-länk: </p>
              <input class="auth" type="text"  v-model="video.url">
            </div>
          </div>
        </draggable>
      </div>
      <button class="auth" @click="addVideo">Lägg till video</button>
    </div>

    <button class="auth" style="position: relative; margin: 40px 0; background-color: #269400" @click="publish">
      <div v-if="publishLoading" class="auth-loader" style="position: absolute; left: 15px; top: 12px"></div>
      Publicera
    </button>

  </div>
</template>

<script>
import BackIcon from '../../assets/icons/back.svg'
import draggable from 'vuedraggable'

export default {
  props: {
    editModel: {},
  },
  data() {
    return {
      title: ['', ''],
      description: ['', ''],
      image: '',
      videos: [],
      imageLoading: false,
      drag: false,
      publishLoading: false,
      english: false,
    }
  },
  metaInfo() {
    return {
      title: 'Admin - Videobibliotek'
    }
  },
  methods: {
    async updateImages(event) {
      console.log(event.target.files[0].name, event.timeStamp)
      this.imageLoading = true
      const url = await this.$store.dispatch('uploadFile', { folder: 'video-collection-images', file: event.target.files[0], time: event.timeStamp })
      this.imageLoading = false
      this.image = url
    },
    async removeImage(e) {
      this.$store.dispatch('removeFile', { url: this.image })
      this.image = ''
    },
    async publish() {
      console.log('Publishing video collection...')
      this.publishLoading = true
      const data = {
        'title': this.title,
        'description': this.description,
        'image': this.image,
        'slug': this.slug(),
        'videos': this.videos,
      }

      await this.$store.dispatch('publishVideoCollection', { data })

      this.publishLoading = false
      this.$router.go(-1)
    },
    addVideo() {
      this.videos.push({
        'title': ['', ''],
        'url': '',
      })
    },
    removeVideo(video) {
      this.videos.splice(this.videos.indexOf(video), 1);
    },
    slug() {
      if(this.editModel) return this.editModel.slug

      var slug = this.title[0].replace(/[åäÅÄ]/g, 'a')
                           .replace(/[öÖ]/g, 'o')
                           .replace(/[^a-zA-Z0-9 ]/g, "")
                           .trim()
                           .replace(/\s/g, "-")
                           .toLowerCase()

      //Test slug for collisions
      var i = 2
      while(this.slugCollides(slug)) {
        slug = `${slug}-${i}`
        i++
      }

      return slug
    },
    slugCollides(slug) {
      for(const data of Object.entries(this.$store.state.videoCollections)) {
        if(slug === data[1].slug) return true
      }
      return false
    }
  },
  components: {
    BackIcon,
    draggable,
  },
  mounted() {
    if(this.editModel) {
      this.title = this.editModel.title
      this.description = this.editModel.description
      this.image = this.editModel.image
      this.videos = this.editModel.videos
    }
  }
  // computed: {
  //   collection() {
  //     for(const collection of this.$store.state.videoCollections) {
  //       if(collection.slug === this.$route.params.slug) {
  //         this.title = collection.title
  //         this.description = collection.description
  //         this.image = collection.image
  //         this.videos = collection.videos
  //         return collection
  //       }
  //     }

  //     return {}
  //   }
  // }
}
</script>

<style>
.admin-collection-form {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
}

.admin-collection-form img {
  min-width: none;
  width: 300px;
  aspect-ratio: 1 / 0.5787;
  object-fit: cover;
}

.handle {
  cursor: pointer;
}
</style>